header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.me {
       border-radius: 12rem 12rem 0 0;
       width: 20rem;
       height: 23rem;
       margin: 0 auto;
       margin-top: 4rem;
}

/*========== CTA ==========*/

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*========== Social ==========*/

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/*=========== Scroll *********/
.scroll_down {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/* ========= Media Queries (Medium Devices) ========== */
@media screen and (max-width: 1024px) {
    .header{
        height: 68vh;
    }
}
/* ========= Media Queries (Small Devices) ========== */
@media screen and (max-width: 600px) {
    .header{
         height: 100vh;
    }

    .header_socials, .scroll_down {
        display: none;
    }
}

